import '../../../styles/pages/spring-summer-2021/touchdown-on-the-red-planet.scss';
import React from "react";
import Layout from '../../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../../components/spring-summer-2021-stories/more-stories"
import Link from "gatsby-plugin-transition-link/AniLink";

import socialBanner from "../../../images/spring-summer-2021/social-banners/touchdown-on-the-red-planet.jpg";

import helicopterLandingComposite from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/helicopter-landing-composite.jpg";

export default class Index extends React.Component {

  render() {
    var pageTitle = 'Touchdown on the Red Planet';
    var pageDesc = 'Mechanical engineering alumna describes ‘seven minutes of terror’ before historic Mars 2020 landing.';
    var slug = 'touchdown-on-the-red-planet';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="mars-wrapper">
          <article className="wrap small story-content dark-content fun-facts" style={{marginTop: '120px'}}>

          <div className="fun-facts-hero">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <ul className="pager">
                <li>
                  <Link
                  cover
                  className="pager-link"
                  bg="
                  url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                  center / cover   /* position / size */
                  no-repeat        /* repeat */
                  scroll           /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  black            /* color */
                  "
                  direction="left"
                  duration={2}
                  to="/spring-summer-2021/touchdown-on-the-red-planet/">1</Link>
                </li>
                <li>
                  <Link
                  cover
                  className="pager-link"
                  bg="
                  url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                  center / cover   /* position / size */
                  no-repeat        /* repeat */
                  scroll           /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  black            /* color */
                  "
                  direction="left"
                  duration={2}
                  to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">2</Link>
                </li>
                <li>
                  <Link
                  cover
                  className="pager-link active"
                  bg="
                  url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                  center / cover   /* position / size */
                  no-repeat        /* repeat */
                  scroll           /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  black            /* color */
                  "
                  direction="left"
                  duration={2}
                  to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">3</Link>
                </li>
                <li>
                  <Link
                  cover
                  className="pager-link"
                  bg="
                  url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                  center / cover   /* position / size */
                  no-repeat        /* repeat */
                  scroll           /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  black            /* color */
                  "
                  direction="left"
                  duration={2}
                  to="/spring-summer-2021/touchdown-on-the-red-planet/history-buff-space-career/">4</Link>
                </li>
              </ul>
              </ScrollAnimation>
          </div>
  
            <h1>Mars 2020 Fun Facts</h1>

            <h2 className="center">Hitching a Ride</h2>
            <div className="fact-section">
              <div className="fact-col">
                <figure>
                  <img src={helicopterLandingComposite} alt="" />
                </figure>

                <h3>“Ingenuity” Robotic Helicopter</h3>
                <p>The car-size Perseverance rover, named “Percy,” brought along the small, autonomous aircraft (weighs less than 4 pounds) for the first test of powered flight on another planet.</p>  
              </div>
              <div className="fact-col">
                <h3>Sounds of Mars</h3>
                <p>Percy is carrying a pair of microphones to provide interesting and historic audio of the arrival and landing at Mars.</p>
    
                <h3>Send Your Name to Mars</h3>
                <p>Percy has three fingernail-sized chips on board containing 10,932,295 names. These names include alumna Lauren DuCharme, her husband, Daniel Tchui ’13 (B.S. mechanical engineering), and her parents, Kelly and James DuCharme.</p>
              </div>
            </div>
            
            <h2 className="center">Percy’s New Home on the Red Planet</h2>
            <div className="fact-section">
              <div className="fact-col center">
                <p>
                  <span className="highlight-text">-81&deg;F</span>
                  <span className="label">Average Temperature</span>
                </p>

                <p>
                  <span className="highlight-text">24</span> hours <span className="highlight-text">37</span> minutes
                  <span className="label">Length of Day</span>
                </p>

                <p>
                  <span className="highlight-text">687</span> days<br />
                  <small>almost twice as long as a year on Earth</small>
                  <span className="label">Length of Year</span>
                </p>

              </div>
              <div className="fact-col">

                <p className="center">
                  <div className="weight-comp">
                    <div className="weight">
                      <span className="highlight-text">100</span> lbs<br />
                      <small>on Earth</small>
                    </div>
                    <div><span className="highlight-text">=</span></div>
                    <div className="weight">
                      <span className="highlight-text">38</span> lbs<br />
                      <small>on Mars</small>
                    </div>
                  </div>

                  <span className="label">Weight and Gravity</span>
                </p>

                <h3>Navajo Names</h3>
                <p>Percy’s team, in collaboration with the Navajo Nation Office of the President and Vice President, is naming features of scientific interest with words in the Navajo language. The first is a rock named “Máaz” — the Navajo word for “Mars.”</p> 
              </div>
            </div>

            <br /><br />
            
            <p className="center"><small>Source: NASA/JPL-Caltech</small></p>

  
          </article>

          <br /><br /><br />

          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" className="center">
            <Link
            className="button mars-button"
            cover
            bg="
            url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
            center / cover   /* position / size */
            no-repeat        /* repeat */
            scroll           /* attachment */
            padding-box      /* origin */
            content-box      /* clip */
            black            /* color */
            "
            direction="right"
            duration={2}
            to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">
              Previous
            </Link>

            <Link
            className="button mars-button"
            cover
            bg="
            url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
            center / cover   /* position / size */
            no-repeat        /* repeat */
            scroll           /* attachment */
            padding-box      /* origin */
            content-box      /* clip */
            black            /* color */
            "
            direction="left"
            duration={2}
            to="/spring-summer-2021/touchdown-on-the-red-planet/history-buff-space-career/">
              Next
            </Link>
          </ScrollAnimation>


        </div>

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 